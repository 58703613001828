/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";

// image
import ocenBluegel from "../../assets/categories/new/1.jpeg";
import leamon from "../../assets/banners/lemon5.jpeg.jpg";
import lemonBodyWash from "../../assets/categories/new/2.jpeg";
// import aloevera from "../../assets/categories/new/alw.jpeg";
import antiAcne from "../../assets/categories/new/3.jpeg";
// import LaceFabric from "../../assets/categories/new/01.jpg";
import iceWipes from "../../assets/categories/new/5.jpeg";
import ads from "../../assets/categories/new/offer.png";
import honeyWipes from "../../assets/categories/new/4.jpeg";
// import lemonWipes from "../../assets/categories/new/lemon-wipes.jpeg";
import LameFabric from "../../assets/categories/new/6.jpeg";
// import babyWash from "../../assets/banners/new/06.jpg";
// import sanityPad from "../../assets/banners/new/44 (1).jpg";
// import Slider from "react-slick";
import sanitaryNapkin from "../../assets/icons/sanitary-napkin.png";
import wipes from "../../assets/icons/wipes.png";
import lotion from "../../assets/icons/lotion.png";
import liquidSoap from "../../assets/icons/liquid-soap.png";
import babyBoy from "../../assets/icons/baby-boy.png";
import Beauty from "../../assets/icons/Beautycare.png";
import HK from "../../assets/icons/HomeandKitchen.png";
import Car from "../../assets/icons/Caraccessories.png";
import HomeImprove from "../../assets/icons/Homeimprovement.png";
import PH from "../../assets/icons/ph-balance.png";
import noDrinks from "../../assets/icons/no-drinks.png";
import parabenFree from "../../assets/icons/atom.png";
import soft from "../../assets/icons/feather.png";
import fragrance from "../../assets/icons/natural.png";
import extraction from "../../assets/icons/extraction.png";
import safe from "../../assets/icons/GMP.jpg";
import fda from "../../assets/icons/ISO.jpg";
import tested from "../../assets/icons/tested.png";
import home1 from "../../assets/home/01.jpg";
import home2 from "../../assets/home/02.jpeg";
import Testimonial from "./testimonial";
import {
  Grid,
  Typography,
  Button,
  Box,
  Tabs,
  Tab,
  AppBar,
  Chip,
} from "@mui/material";
import TopSelling from "./TopSelling";
import NewArrivals from "./NewArrivals";
import Banner from "../Banner";
import { FaRegHeart } from "react-icons/fa6";
import bestsellers from "./../../data/home/bestsellers";
import actions from "./../../redux/products/actions";

function Categories() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const showOnMobile = windowSize?.current?.[0] < 769;
  const scrollContainerRef = useRef(null);

  // Function to scroll the container to the left
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -240, behavior: "smooth" });
      console.log('"first"', "first");
    }
  };

  // Function to scroll the container to the right
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 240, behavior: "smooth" });
      console.log('"first"', "first");
    }
  };
  const [value, setValue] = React.useState(0);
  const [active, setActive] = React.useState("New");

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const navigate = useNavigate();
  const handleMouseEnter = (e) => {
    e.target.play();
  };

  const handleMouseLeave = (e) => {
    e.target.pause();
    // e.target.currentTime = 0;
  };
  const categories = [
    { img: Car, name: "Car accessories" },
    { img: HomeImprove, name: "Home improvement" },
    { img: Beauty, name: "Beauty care" },
    { img: HK, name: "Home and Kitchen" },
    // { img: wipes, name: "Wipes" },
  ];
  const bestSeller = [
    {
      img: "https://storage.googleapis.com/lieve-bucket/product/1733914760560.jpg",
      name: "Metal Silicon Key Cover",
    },
    {
      img: "https://storage.googleapis.com/lieve-bucket/product/1732182787230.jpg",
      name: "Washing Machine Cleaner Descaler",
    },
    {
      img: "https://storage.googleapis.com/lieve-bucket/product/1732178635180.jpg",
      name: "LIEVE Honey & Almond Facial Wipes",
    },
    {
      img: "https://storage.googleapis.com/lieve-bucket/product/1732183633896.jpg",
      name: "X-Type Microfiber Hardwood Floor Mop",
    },
    {
      img: "https://storage.googleapis.com/lieve-bucket/product/1732184464978.jpg",
      name: "5 PCS Microfiber Car Duster Kit Interior",
    },
    // { img: LameFabric, name: "Baby Powder" },
  ];
  const standards = [
    {
      img: tested,
      name: "Dermatologically Tested",
      desc: "We ensure each product is tested clinically on the sensitive human skin to ensure that it is not allergic.",
    },
    {
      img: fda,
      name: "ISO Certified",
      desc: "Lieve is ISO Certified, which means we are 100% safe to be used for babies .",
    },
    {
      img: safe,
      name: "Good manufacturing practice",
      desc: "Good manufacturing practice (GMP) is a system for ensuring that products are consistently produced and controlled according to quality standards",
    },
    // {
    //   img: safe,
    //   name: "Made Safe Certified",
    //   desc: "The MADE SAFE (Made with Safe Ingredients™) seal means that a product is literally made with safe ingredients.",
    // },
  ];
  const [data, setData] = useState([
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/WhatsApp%20Video%202023-09-17%20at%2012.05.24%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C6ne5Sgtmix/?igsh=dGhhbmdqdXV1MnJr",
      embedUrl: "https://www.instagram.com/reel/C6ne5Sgtmix/embed",
      url: "LEMON FACIAL WIPES",
      id: "65b73db6a058b07e02163fed",
    },
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/Lieve%20facewash%20reel%2001%203%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C6tKDkHPLKT/?igsh=MXVuc2dramd3Z25obw==",

      url: "ALOE VERA FACE WASH",
      id: "65ba3afb7c609919e992f49d",
    },
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/Bodywash%20reel%2001%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C7TLTvGhsl7/?igsh=emYwZmhjdGFucG9t",

      url: "OCEAN BLUE BODY WASH",
      id: "65b5ef5031d1fdf808361a33",
    },
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/Gel%20reel%2001%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C621PMwPBHo/?igsh=MTZ5NXFsb2p3MHBnZA==",

      url: "ALOE VERA GEL",
      id: "65ba4559daa43a376f005714",
    },
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/Lieve%20Babywash%20reel%2001%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C7g_uoxB5Vp/?igsh=dmowdXh1ZDl2ejln",

      url: "BABY BODY WASH",
      id: "65ba47addaa43a376f00579b",
    },
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/lieves%20BABY%20POWDER%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C7oJRbFMvkv/?igsh=NWxndGMxNWRsZDVl",

      url: "BABY POWDER",
      id: "65ba497bdaa43a376f00583b",
    },
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/IMG_8715%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C5SccqdRaes/?igsh=eDNia280aTFyNDV5",

      url: "UBTAN FACE WASH",
      id: "65ba4193daa43a376f0056bd",
    },
  ]);
  const feature = [
    {
      img: PH,
      name: "pH-balanced",
      desc: "Maintains your skin’s natural acidity for healthy, irritation-free skin.",
    },
    {
      img: noDrinks,
      name: "Alcohol-free",
      desc: "Preserves your skin’s natural moisture with a gentle, alcohol-free formula.",
    },
    {
      img: parabenFree,
      name: "Paraben-Free",
      desc: "Safeguards your skin by avoiding preservatives that may cause irritation.",
    },
    {
      img: soft,
      name: "Soft Texture",
      desc: "Delivers a luxuriously smooth feel for a gentle touch on your skin.",
    },
    {
      img: fragrance,
      name: "Natural Fragrance",
      desc: "Offers a subtle, pleasant aroma derived from natural essential oils.",
    },
    {
      img: extraction,
      name: "Natural Extracts",
      desc: "Infuses your skincare with the benefits of pure, plant-based ingredients.",
    },
  ];
  const news = [
    {
      img: ocenBluegel,
      name: "pH-balanced",
      desc: "Maintains your skin’s natural acidity for healthy, irritation-free skin.",
    },
    {
      img: ocenBluegel,
      name: "Alcohol-free",
      desc: "Preserves your skin’s natural moisture with a gentle, alcohol-free formula.",
    },
    {
      img: LameFabric,
      name: "Paraben-Free",
      desc: "Safeguards your skin by avoiding preservatives that may cause irritation.",
    },
    {
      img: iceWipes,
      name: "Soft Texture",
      desc: "Delivers a luxuriously smooth feel for a gentle touch on your skin.",
    },
    {
      img: honeyWipes,
      name: "Natural Fragrance",
      desc: "Offers a subtle, pleasant aroma derived from natural essential oils.",
    },
    {
      img: honeyWipes,
      name: "Natural Fragrance",
      desc: "Offers a subtle, pleasant aroma derived from natural essential oils.",
    },
    {
      img: honeyWipes,
      name: "Natural Fragrance",
      desc: "Offers a subtle, pleasant aroma derived from natural essential oils.",
    },
    {
      img: honeyWipes,
      name: "Natural Fragrance",
      desc: "Offers a subtle, pleasant aroma derived from natural essential oils.",
    },
    {
      img: antiAcne,
      name: "Natural Extracts",
      desc: "Infuses your skincare with the benefits of pure, plant-based ingredients.",
    },
  ];
  const BestCard = (props) => {
    return (
      <div
        className="custom-class
      "
      >
        <div className="custom-container">
          <img src={props?.data?.img} class="custom-image" alt={"props"} />

          {/* {props?.isNew && ( */}
          {/* <div class="custom-chip">
            <Chip value="New" className="chip" />
          </div> */}
          {/* )} */}

          {props?.isProduct && (
            <div class="wishlist-button">
              <Button class="wishlist-btn">
                <FaRegHeart className="heart-icon" />
              </Button>
            </div>
          )}
        </div>
        <p className="card-text">{props?.data?.name}</p>
        <button onClick={() => navigate("/products")} className="explore-btn">
          Explore Products
        </button>
      </div>
    );
  };
  const BestCards = (props) => {
    return (
      <div
        className="custom-class-sellings
      "
      >
        <div className="custom-container">
          <img src={props?.data?.img} class="custom-image" alt={"props"} />

          {/* {props?.isNew && ( */}
          {/* <div class="custom-chip">
            <Chip value="New" className="chip" />
          </div> */}
          {/* )} */}

          {props?.isProduct && (
            <div class="wishlist-button">
              <Button class="wishlist-btn">
                <FaRegHeart className="heart-icon" />
              </Button>
            </div>
          )}
        </div>
        <p className="card-text">{props?.data?.name}</p>
        <button onClick={() => navigate("/products")} className="explore-btn">
          Explore Products
        </button>
      </div>
    );
  };

  return (
    <section className="categories-grid pt-30">
      <div className="container">
        {/* <div className="section-heading"> */}
        {/* <h2>PRODUCT CATEGORIES</h2> */}
        {/* </div> */}
        <div className="categories-icon">
          {categories?.map((obj) => {
            return (
              <div className="categories-block">
                <div
                  onClick={() =>
                    navigate(
                      `/products/&pattern=${encodeURIComponent(obj.name)}`
                    )
                  }
                  className="categories-block-icon"
                >
                  <img src={obj?.img} alt="icon" />
                </div>
                <p>{obj?.name}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Banner />
      <div className="container">
        {/* <div onClick={() => navigate("/products")} className="explore-all">
        Explore Products
        <div className="explore-all-arrow">
          <GoArrowUpRight className="explore-all-arrow-icon" />
        </div>
      </div> */}
      </div>
      <div className="container mt-5 secondary-container">
        <div className="section-heading ">
          <h2>BEST SELLERS</h2>
          <p>Explore our Bestsellers and Find Your Favorites</p>
        </div>
        {!showOnMobile ? (
          <div className="bestseller">
            {bestSeller?.map((obj, index) => {
              // const isOdd = index % 2 !== 0;
              return (
                <BestCard key={index} data={obj} isProduct={false} />
                // isOdd ? (
                // <div className="bestseller-block up-radius">
                //   <div className="bestseller-block-icon">
                //     <img src={obj?.img} alt="icon" />
                //   </div>
                //   <div className="bestseller-content">
                //     <p>{obj?.name}</p>
                //     <div
                //       onClick={() => navigate("/products")}
                //       className="explore-all-arrow"
                //     >
                //       <GoArrowUpRight className="explore-all-arrow-icon" />
                //     </div>
                //   </div>
                // </div>
              );
            })}
          </div>
        ) : (
          <div className="bestsellermobile ">
            {bestSeller?.map((obj, index) => {
              // const isOdd = index % 2 !== 0;
              return (
                <BestCard key={index} data={obj} isProduct={false} />
                // isOdd ? (
                // <div className="bestseller-block up-radius">
                //   <div className="bestseller-block-icon">
                //     <img src={obj?.img} alt="icon" />
                //   </div>
                //   <div className="bestseller-content">
                //     <p>{obj?.name}</p>
                //     <div
                //       onClick={() => navigate("/products")}
                //       className="explore-all-arrow"
                //     >
                //       <GoArrowUpRight className="explore-all-arrow-icon" />
                //     </div>
                //   </div>
                // </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="container" style={{ display: "none" }}>
        <Box className="standards-container">
          {/* First Section: Image on the left, content on the right */}
          <Grid
            container
            spacing={2}
            sx={{ padding: "24px" }}
            alignItems="center"
          >
            {/* Left side image */}
            <Grid item xs={12} md={4} alignItems="center">
              <Box
                component="img"
                src={home1}
                alt="Product 1"
                sx={{ width: "80%", height: "auto", margin: "auto" }}
              />
            </Grid>
            {/* Right side content */}
            <Grid item xs={12} md={8}>
              <h2>FACE WASH</h2>
              <div className="standards-text1">
                <Typography variant="body1" paragraph>
                  Lieve Using an aloe vera face wash works for all skin types
                  because it contains antibacterial, anti-inflammatory, and
                  healing properties. It helps reverse the damage caused by
                  pollution and UV rays. Aloe Vera deeply hydrates the skin and
                  provides a soothing effect on the skin without making it
                  greasy.{" "}
                </Typography>
              </div>{" "}
              <Button
                variant="contained"
                onClick={() => navigate("product/ALOE VERA FACE WASH")}
                style={{ background: "#000" }}
              >
                Shop Now
              </Button>
            </Grid>
          </Grid>

          {/* Second Section: Image on the right, content on the left */}
          <Grid
            container
            spacing={2}
            sx={{ padding: "24px", backgroundColor: "#f5f5f5" }}
            alignItems="center"
          >
            {/* Right side image */}
            <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
              <h2>FACIAL WIPES</h2>
              {/* <div className={`news-heading `}>
                <h2>FACIAL WIPES</h2>
                </div> */}
              <div className="standards-text1">
                <Typography variant="body1" paragraph>
                  Lieve Wet wipes for face use are to remove dirt, oil, and
                  makeup from the skin. They create a clean slate for the rest
                  of the skincare products to be absorbed by their skin. This
                  can help to ensure that one’s other skincare products are
                  working at their full potential, providing the maximum
                  benefits for their skin.{" "}
                </Typography>
              </div>
              <Button
                variant="contained"
                onClick={() => navigate("product/LEMON FACIAL WIPES")}
                style={{ background: "#000" }}
              >
                Shop Now
              </Button>
            </Grid>

            {/* Left side content */}
            <Grid
              item
              xs={12}
              md={4}
              order={{ xs: 1, md: 2 }}
              alignItems="center"
            >
              <Box
                component="img"
                src={home2}
                alt="Product 2"
                sx={{
                  width: "80%",
                  height: "auto",
                  margin: "auto",
                  float: { md: "right", xs: "left" },
                }}
              />
            </Grid>
          </Grid>
          {/* Third Section: Image on the left, content on the right */}
          <Grid
            container
            spacing={2}
            sx={{ padding: "24px", display: "none" }}
            alignItems="center"
          >
            {/* Left side image */}
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={bestSeller[0].img}
                alt="Product 1"
                sx={{ width: "50%", height: "auto" }}
              />
            </Grid>
            {/* Right side content */}
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                Product Title 1
              </Typography>
              <Typography variant="body1" paragraph>
                This is a brief description of the product. Highlight the key
                features and benefits.
              </Typography>
              <Button variant="contained" color="primary">
                Shop Now
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
      {/* <div className="container">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} p={5} pb={0}>
          <AppBar sx={{ bgcolor: "black" }} position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              centered
              variant="fullWidth"
              aria-label="full width tabs example"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "#fff",
                },
              }}
              TabProps={{
                sx: {
                  backgroundColor: "#fff",
                },
              }}
            >
              <Tab label="New Arrivals" {...a11yProps(0)} />
              <Tab label="Top Pick" {...a11yProps(1)} />
              <Tab label="Sale Items" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <NewArrivals isNotHeader={true} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <TopSelling />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <TopSelling />
        </CustomTabPanel>
      </div> */}

      <div className="container mt-5 secondary-container">
        <div
          className="section-heading"
          style={{ justifyContent: "space-between", flexWrap: "wrap" }}
        >
          <div className="section-heading flex-col">
            <h2>Discover our products</h2>
            <p>
              Discover the newest additions to our collection - handpicked just
              for you!
            </p>
          </div>

          {/* <div className="section-nav section-heading">
            <div
              className={`section-tab ${active === "New" && "active"}`}
              onClick={() => setActive("New")}
            >
              New Arrivals
            </div>
            <div
              className={`section-tab ${active === "Top" && "active"}`}
              onClick={() => setActive("Top")}
            >
              Top Pick
            </div>
            <div
              className={`section-tab ${active === "Sale" && "active"}`}
              onClick={() => setActive("Sale")}
            >
              Sale Items
            </div>
          </div> */}
        </div>
        {active === "New" ? (
          <NewArrivals isNotHeader={true} />
        ) : active === "Top" ? (
          <TopSelling />
        ) : (
          <TopSelling />
        )}
      </div>
      {/* <div className="container news">
        <div className="news-container">
          <div className={`news-heading `}>
            <h5>Discover</h5>
            <h2>Our Story</h2>
            <p>
              we believe that true wellness begins with compassionate care and
              personalized attention. Our journey started with a simple idea to
              create a space where health and well-being are not just services,
              but an experience of comfort, trust, and expert guidance.
            </p>
          </div>

          <div className="news-scroll-container">
            <div className="news-container-div">
              {data?.map((obj, index) => (
                <div
                  className="news-block"
                  key={index}
                  onClick={() => window.open(obj?.video, "_blank")}
                >
                  <video
                    
                    muted
                    loop
                    width={"100%"}
                    style={{
                      objectFit: "cover",
                    }}
                    className="img"
                    preload="auto"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <source src={obj?.videoBucket} type="video/mp4" />
                  </video>

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/product/${obj?.url}`);
                    }}
                    class="glow-on-hover"
                  >
                    Shop now!
                  </button>
                  <p onClick={() => navigate(`/product/${obj?.url}`)}>
                    Shop now!
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
      <div className="container" style={{ display: "none" }}>
        <div className="standards-container">
          <h3>Super Safe Standards</h3>
          <div className="standards">
            {standards?.map((obj, index) => {
              return (
                <div className="standards-block">
                  <img src={obj?.img} alt="" />
                  <div className="standards-text">
                    <h5>{obj?.name}</h5>
                    <p>{obj?.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="container mt-5 secondary-container">
        <div
          className="section-heading"
          style={{ justifyContent: "space-between", flexWrap: "wrap" }}
        >
          <div className="section-heading flex-col">
            <h2>Discover our products</h2>
            <p>
              Discover the newest additions to our collection - handpicked just
              for you!
            </p>
          </div>
        </div>
      </div>
      <div
        className="container mt-5 secondary-container"
        style={{
          display: "flex",
          flexDirection: "row",

          gap: "10px",
        }}
      >
        {/* <div style={{ width: "70%", overflow: "hidden" }}>
          <div className="bestsellings custom-class-sellings">
            {bestSeller?.map((obj, index) => {
              // const isOdd = index % 2 !== 0;
              return <BestCards key={index} data={obj} isProduct={false} />;
            })}
          </div>
        </div> */}
        <div style={{ position: "relative", overflow: "hidden" }}>
          {/* Left Arrow Button */}
          <button onClick={scrollLeft} className="arrow left-arrow ">
            <div className="left-arrow">&lt;</div>
          </button>

          <div
            className="bestsellings custom-class-sellings"
            ref={scrollContainerRef}
          >
            {bestSeller?.map((obj, index) => (
              <BestCards key={index} data={obj} isProduct={false} />
            ))}
          </div>

          {/* Right Arrow Button */}
          <button onClick={scrollRight} className="arrow right-arrow ">
            <div className="right-arrow">&gt;</div>
          </button>
        </div>
        <div
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginBlock: "10px",
            minHeight: "100%",
          }}
        >
          <div className=" ads custom-class-sellings ">
            <img
              src={ads}
              alt="leamon"
              // className="bestsellings"
              style={{ width: "195px", height: "100%", borderRadius: "10px" }}
            />
          </div>
        </div>
      </div>
      <div
        className="container secondary-container mt-5"
        style={{
          display: "flex",
          flexDirection: "column",
          // border: "2px solid red",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "3px solid black",
            width: "100%",
          }}
        >
          <h3>why shop with us?</h3>
        </div>
        <div
          // className="categories-block"
          style={{
            // border: "1px solid red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop:"10px",
          }}
        >
          <div
            className="workmain"

            // style={{
            //   // border: "1px solid black",
            //   width: "80%",
            //   display: "flex",
            //   flexDirection: "row",
            //   gap: "5px",
            //   justifyContent: "center",
            //   alignItems: "center",
            // }}
          >
            <div
              // className="categories-block"

              style={{
                objectFit: "contain",
                width: "20%",
                display: "flex",
                flexDirection: "column",
                // border: "1px solid black",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={ads}
                alt="leamon"
                // className="bestsellings"
                className="footerads "
                style={{  width: "70%" }}
              />
              <div
              className="work-content"
                style={{
                  // width: "70%",
                  maxHeight: "50px",
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  // wordWrap: "break-word",
                  // overflowWrap: "break-word",
                  overflowY:"hidden",
                  // border: "1px solid black",
                  // padding:"0px 10px"
                }}
              >
                ss ss ss ss ss ss ss s s s s s s s s s sww
              </div>

              {/* <div style={{height:"70px", border:"1px solid red",width:"80%"}}>a</div>
              <div style={{height:"auto", border:"1px solid red",width:"80%"}}>
                <p>awwwwwwwwdddddddddddd</p>
              </div> */}
            </div>
            <div
              // className="categories-block"

              style={{
                objectFit: "contain",
                width: "20%",
                display: "flex",
                flexDirection: "column",
                // border: "1px solid black",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={ads}
                alt="leamon"
                // className="bestsellings"
                className="footerads "
                style={{  width: "70%" }}
              />
              <div
                              className="work-content"

              >
                ss ss ss ss ss ss ss s s s s s s s s s s{" "}
              </div>

              {/* <div style={{height:"70px", border:"1px solid red",width:"80%"}}>a</div>
              <div style={{height:"auto", border:"1px solid red",width:"80%"}}>
                <p>awwwwwwwwdddddddddddd</p>
              </div> */}
            </div>
            <div
              // className="categories-block"

              style={{
                objectFit: "contain",
                width: "20%",
                display: "flex",
                flexDirection: "column",
                // border: "1px solid black",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={ads}
                alt="leamon"
                // className="bestsellings"
                className="footerads "
                style={{  width: "70%" }}
              />
              <div
                           className="work-content"

              >
                ss ss ss ss ss ss ss s s s s s s s s s s{" "}
              </div>

              {/* <div style={{height:"70px", border:"1px solid red",width:"80%"}}>a</div>
              <div style={{height:"auto", border:"1px solid red",width:"80%"}}>
                <p>awwwwwwwwdddddddddddd</p>
              </div> */}
            </div>
            <div
              // className="categories-block"

              style={{
                objectFit: "contain",
                width: "20%",
                display: "flex",
                flexDirection: "column",
                // border: "1px solid black",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={ads}
                alt="leamon"
                // className="bestsellings"
                className="footerads "
                style={{ width: "70%" }}
              />
              <div
                              className="work-content"

              >
                ss ss ss ss ss ss ss s s s s s s s s s s{" "}
              </div>
              {/* <div style={{height:"70px", border:"1px solid red",width:"80%"}}>a</div>
              <div style={{height:"auto", border:"1px solid red",width:"80%"}}>
                <p>awwwwwwwwdddddddddddd</p>
              </div> */}
            </div>
            <div
              // className="categories-block"

              style={{
                objectFit: "contain",
                width: "20%",
                display: "flex",
                flexDirection: "column",
                // border: "1px solid black",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={ads}
                alt="leamon"
                // className="bestsellings"
                className="footerads "
                style={{width: "70%" }}
              />
              <div
                              className="work-content"

              >
                ss ss ss ss ss ss ss s s s s s s s s s s{" "}
              </div>
              {/* <div style={{height:"70px", border:"1px solid red",width:"80%"}}>a</div>
              <div style={{height:"auto", border:"1px solid red",width:"80%"}}>
                <p>awwwwwwwwdddddddddddd</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="natural-container">
          <div className="natural-heading">
            <h2>Experience Ultimate Natural Beauty</h2>
          </div>{" "}
          <div className="bg-image"></div>
          <div className="feature-container-div">
            <div className="feature-container">
              {feature?.map((obj, index) => {
                const isOdd = index % 2 !== 0;

                return !isOdd ? (
                  <div className="feature-block">
                    <img src={obj?.img} alt="" />
                    <div>
                      <h5>{obj?.name}</h5>
                      <h6>{obj?.desc}</h6>
                    </div>
                  </div>
                ) : (
                  <div className="feature-block">
                    <div>
                      <h5>{obj?.name}</h5>
                      <h6>{obj?.desc}</h6>
                    </div>
                    <img src={obj?.img} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="container">
        <Testimonial />
      </div> */}
    </section>
  );
}

export default Categories;
