/* eslint-disable no-unused-vars */
import React, { useState,useRef, useEffect } from "react";
import ProductBox from "../ProductBox";
import { getAllProducts, getProductById } from "../../service/api";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import actions from "../../redux/products/actions";
import { useSelector } from "react-redux";
import SpinnerComponent from "../Spinner";
import { FaRegHeart } from "react-icons/fa6";
import { Button, Tooltip } from "@mui/material";

function NewArrivals(props) {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
      const showOnMobile = windowSize?.current?.[0] < 769;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const products = useSelector((state) => state.products);

  const [allProducts, setallProducts] = useState([]);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (allProducts?.length >= 4) {
      let selectedArrays = [];

      while (selectedArrays?.length < 4) {
        let randomIndex = Math.floor(Math.random() * allProducts?.length);
        let selectedArray = allProducts[randomIndex];

        if (!selectedArrays?.includes(selectedArray)) {
          selectedArrays?.push(selectedArray);
        }
      }
      setSelected(selectedArrays);
    } else {
      setSelected(allProducts);
    }
  }, [allProducts]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // if(products?.wishListTrigger === "start")
    // {
    //   setLoading(true)
    //   return
    // }
    // const obj = {}
    // obj.page = 1
    // obj.sizePerPage = 10
    const obj = {};

    obj.page = 1;
    obj.sizePerPage = 10;
    setLoading(true);
    getAllProducts(obj).then((res) => {
      if (res?.status) console.info(res?.data?.productData);
      if (id) {
        let arr = res?.data[0]?.docs?.filter((obj) => obj?._id !== id);
        setallProducts(arr);
      } else {
        setallProducts(res?.data[0]?.docs);
      }
      setLoading(false);
    });

    return () => {
      setallProducts([]);
    };
  }, [id]);

  let [wishlistActiveId, setwishlistActiveId] = useState(null);
  const handleProductClick = (data) => {
    console.log("data---->", data);
    dispatch(
      actions.setSelectedProductId({
        selectedProductId: data?._id,
        selectedProductColorId: data?.productImage?.color,
      })
    );
    let wishlistActive = false;
    if (data?.WishlistProduct?._id) {
      wishlistActive = true;
    }

    dispatch(
      actions.setWishlistData({
        wishlistActive: wishlistActive,
        productId: data?._id,
        colorId: data?.productImage.color,
      })
    );
    navigate(`/product/${data?.name}`);
  };

  let watch = 0;
  const colorIdFilterFunction = (colorArray, productIndex) => {
    let colorArrIndex;
    for (let x = 0; x < colorArray.length; x++) {
      if (watch === 0) {
        colorArrIndex = x;
        watch = x + 1;
        if (x === colorArray.length - 1) {
          watch = 0;
        }
        break;
      } else if (x === watch) {
        colorArrIndex = x;
        watch = x + 1;

        if (x === colorArray.length - 1) {
          watch = 0;
        }
        break;
      }
    }

    const resultIndex = productIndex - colorArrIndex;
    const finalIndex = productIndex - resultIndex;
    // console.log("productIndex", productIndex , "colorArrIndex",colorArrIndex , "finalIndex",finalIndex)
    return finalIndex;
  };

  return (
    <section className="new-arrivals pb-50">
      <div className="container">
        {!props?.isNotHeader && (
          <div className="section-heading">
            <h2>Shop Our New Arrivals</h2>
            {/* <p>Unique hand-picked items</p> */}
          </div>
        )}
        {!showOnMobile?(<div className="bestseller">
          {!loading ? (
            selected &&
            selected?.length !== 0 &&
            selected?.map((obj, index) => {
              return (
                <>
                  <div
                    key={index}
                    onClick={() => {
                      handleProductClick(obj);
                    }}
                    style={{ cursor: "pointer" }}
                    className="custom-class"
                  >
                    <div className="custom-container">
                      <img
                        src={obj?.productImage[0]}
                        class="custom-image"
                        alt={"props"}
                      />

                      {/* {props?.isNew && ( */}
                      {/* <div class="custom-chip">
            <Chip value="New" className="chip" />
          </div> */}
                      {/* )} */}

                      {/* {props?.isProduct && ( */}
                      <div class="wishlist-button">
                        <Button class="wishlist-btn">
                          <FaRegHeart className="heart-icon" />
                        </Button>
                      </div>
                      {/* )} */}
                    </div>
                    <Tooltip title={obj?.name} arrow>
                      <p
                        className="card-text"
                        // style={{ fontWeight: 800, fontSize: "0.85rem",height:"45px" }}
                      >
                        {obj?.name?.length > 65
                          ? `${obj?.name?.slice(0, 64)}...`
                          : obj?.name}
                      </p>
                    </Tooltip>
                    <p className=" price">
                      ₹{obj?.specialAmount}
                      &nbsp;
                      <span className="specialAmount">₹{obj?.amount}</span>
                    </p>
                    <button
                      onClick={() => navigate("/products")}
                      className="explore-btn"
                    >
                      Add to cart
                    </button>
                  </div>
                </>
              );
            })
          ) : (
            <div className="bestseller">
              {Array.from({ length: 5 }).map((_, idx) => {
                return (
                  <div
                    key={idx}
                    class="custom-class"
                    style={{ maxWidth: "220px" }}
                  >
                    <div class="custom-container">
                      <div class="skeleton skeleton-image"></div>
                      <div class="wishlist-button">
                        <div class="skeleton skeleton-button"></div>
                      </div>
                    </div>
                    <div class="skeleton skeleton-text"></div>
                    <div class="skeleton skeleton-price"></div>
                    <div class="skeleton skeleton-button"></div>
                  </div>
                );
              })}
            </div>
          )}
        </div>):(<div className="bestsellermobile">
          {!loading ? (
            selected &&
            selected?.length !== 0 &&
            selected?.map((obj, index) => {
              return (
                <>
                  <div
                    key={index}
                    onClick={() => {
                      handleProductClick(obj);
                    }}
                    style={{ cursor: "pointer" }}
                    className="custom-class"
                  >
                    <div className="custom-container">
                      <img
                        src={obj?.productImage[0]}
                        class="custom-image"
                        alt={"props"}
                      />

                      {/* {props?.isNew && ( */}
                      {/* <div class="custom-chip">
            <Chip value="New" className="chip" />
          </div> */}
                      {/* )} */}

                      {/* {props?.isProduct && ( */}
                      <div class="wishlist-button">
                        <Button class="wishlist-btn">
                          <FaRegHeart className="heart-icon" />
                        </Button>
                      </div>
                      {/* )} */}
                    </div>
                    <Tooltip title={obj?.name} arrow>
                      <p
                        className="card-text"
                        // style={{ fontWeight: 800, fontSize: "0.85rem",height:"45px" }}
                      >
                        {obj?.name?.length > 65
                          ? `${obj?.name?.slice(0, 64)}...`
                          : obj?.name}
                      </p>
                    </Tooltip>
                    <p className=" price">
                      ₹{obj?.specialAmount}
                      &nbsp;
                      <span className="specialAmount">₹{obj?.amount}</span>
                    </p>
                    <button
                      onClick={() => navigate("/products")}
                      className="explore-btn"
                    >
                      Add to cart
                    </button>
                  </div>
                </>
              );
            })
          ) : (
            <div className="bestsellermobile">
              {Array.from({ length: 5 }).map((_, idx) => {
                return (
                  <div
                    key={idx}
                    class="custom-class"
                    style={{ maxWidth: "220px" }}
                  >
                    <div class="custom-container">
                      <div class="skeleton skeleton-image"></div>
                      <div class="wishlist-button">
                        <div class="skeleton skeleton-button"></div>
                      </div>
                    </div>
                    <div class="skeleton skeleton-text"></div>
                    <div class="skeleton skeleton-price"></div>
                    <div class="skeleton skeleton-button"></div>
                  </div>
                );
              })}
            </div>
          )}
        </div>)}
        
      </div>
    </section>
  );
}

export default NewArrivals;
