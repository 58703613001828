/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import ProductBox from "../ProductBox";
import { getAllProducts, getProductById } from "../../service/api";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import actions from "../../redux/products/actions";
import { useSelector } from "react-redux";
import SpinnerComponent from "../Spinner";
import { FaRegHeart } from "react-icons/fa6";
import { Button } from "@mui/material";

function TopSelling() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const products = useSelector((state) => state.products);

  const [allProducts, setallProducts] = useState([]);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (allProducts?.length >= 4) {
      let selectedArrays = [];

      while (selectedArrays?.length < 4) {
        let randomIndex = Math.floor(Math.random() * allProducts?.length);
        let selectedArray = allProducts[randomIndex];

        if (!selectedArrays?.includes(selectedArray)) {
          selectedArrays?.push(selectedArray);
        }
      }
      setSelected(selectedArrays);
    } else {
      setSelected(allProducts);
    }
  }, [allProducts]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // if(products?.wishListTrigger === "start")
    // {
    //   setLoading(true)
    //   return
    // }
    // const obj = {}
    // obj.page = 1
    // obj.sizePerPage = 10
    const obj = {};

    obj.page = 1;
    obj.sizePerPage = 10;
    setLoading(true);
    getAllProducts(obj).then((res) => {
      if (res?.status) console.info(res?.data?.productData);
      if (id) {
        let arr = res?.data[0]?.docs?.filter((obj) => obj?._id !== id);
        setallProducts(arr);
      } else {
        setallProducts(res?.data[0]?.docs);
      }
      setLoading(false);
    });

    return () => {
      setallProducts([]);
    };
  }, [id]);

  let [wishlistActiveId, setwishlistActiveId] = useState(null);
  const handleProductClick = (data) => {
    console.log("data---->", data);
    dispatch(
      actions.setSelectedProductId({
        selectedProductId: data?._id,
        selectedProductColorId: data?.productImage?.color,
      })
    );
    let wishlistActive = false;
    if (data?.WishlistProduct?._id) {
      wishlistActive = true;
    }

    dispatch(
      actions.setWishlistData({
        wishlistActive: wishlistActive,
        productId: data?._id,
        colorId: data?.productImage.color,
      })
    );
    navigate(`/product/${data?.name}`);
  };

  let watch = 0;
  const colorIdFilterFunction = (colorArray, productIndex) => {
    let colorArrIndex;
    for (let x = 0; x < colorArray.length; x++) {
      if (watch === 0) {
        colorArrIndex = x;
        watch = x + 1;
        if (x === colorArray.length - 1) {
          watch = 0;
        }
        break;
      } else if (x === watch) {
        colorArrIndex = x;
        watch = x + 1;

        if (x === colorArray.length - 1) {
          watch = 0;
        }
        break;
      }
    }

    const resultIndex = productIndex - colorArrIndex;
    const finalIndex = productIndex - resultIndex;
    // console.log("productIndex", productIndex , "colorArrIndex",colorArrIndex , "finalIndex",finalIndex)
    return finalIndex;
  };

  return (
    <section className="new-arrivals pt-50 pb-50">
      <div className="container">
        {/* <div className="section-heading"> */}
        {/* <h2>Top Pick</h2> */}
        {/* <p>Unique hand-picked items</p> */}
        {/* </div> */}

        <div className="row">
          {!loading ? (
            selected &&
            selected?.length !== 0 &&
            selected?.map((obj) => {
              return (
                // allProducts.map((data, i) => {
                //   console.info("data",data)
                //   // if (data?.color.filter((e) => e?._id === data?.productImage?.color)?.[0]?.name === 'Black') {
                //     return (
                //       <div className="col-lg-3 col-sm-12">
                //         <div className='h-100' onClick={() => {
                //           handleProductClick(data)
                //         }} style={{ textDecoration: 'none', color: '#000' }}>
                //           <ProductBox
                //             key={data?._id}
                //             id={data?._id}
                //             //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
                //             colorId={data?.productImage?.color}
                //             img={data?.productImage?.front[0]}
                //             name={data?.name}
                //             price={data?.amount}
                //             subDescriptions={data?.subDescriptions}
                //             composition={data?.content}
                //             wishList={data?.WishlistProduct}
                //           />
                //         </div>
                //       </div>
                //     );
                //   // } else if (data?.color.filter((e) => e?._id === data?.productImage?.color)?.[0]?.name === 'Red') {
                //   //   return (
                //   //     <div className="col-lg-3 col-sm-12">
                //   //       <div className='h-100' onClick={() => {
                //   //         handleProductClick(data)
                //   //       }} style={{ textDecoration: 'none', color: '#000' }}>
                //   //         <ProductBox
                //   //           key={data?._id}
                //   //           id={data?._id}
                //   //           //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
                //   //           colorId={data?.productImage?.color}
                //   //           img={data?.productImage?.front[0]}
                //   //           name={data?.name}
                //   //           price={data?.amount}
                //   //           subDescriptions={data?.subDescriptions}
                //   //           composition={data?.content}
                //   //           wishList={data?.WishlistProduct}
                //   //         />
                //   //       </div>
                //   //     </div>
                //   //   );
                //   // } else if (data?.color.filter((e) => e?._id === data?.productImage?.color)?.[0]?.name === 'Silver') {
                //   //   return (
                //   //     <div className="col-lg-3 col-sm-12">
                //   //       <div className='h-100' onClick={() => {
                //   //         handleProductClick(data)
                //   //       }} style={{ textDecoration: 'none', color: '#000' }}>
                //   //         <ProductBox
                //   //           key={data?._id}
                //   //           id={data?._id}
                //   //           //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
                //   //           colorId={data?.productImage?.color}
                //   //           img={data?.productImage?.front[0]}
                //   //           name={data?.name}
                //   //           price={data?.amount}
                //   //           subDescriptions={data?.subDescriptions}
                //   //           composition={data?.content}
                //   //           wishList={data?.WishlistProduct}
                //   //         />
                //   //       </div>
                //   //     </div>
                //   //   );
                //   // } else if (data?.color.filter((e) => e?._id === data?.productImage?.color)?.[0]?.name === 'Maroon') {
                //   //   return (
                //   //     <div className="col-lg-3 col-sm-12">
                //   //       <div className='h-100' onClick={() => {
                //   //         handleProductClick(data)
                //   //       }} style={{ textDecoration: 'none', color: '#000' }}>
                //   //         <ProductBox
                //   //           key={data?._id}
                //   //           id={data?._id}
                //   //           //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
                //   //           colorId={data?.productImage?.color}
                //   //           img={data?.productImage?.front[0]}
                //   //           name={data?.name}
                //   //           price={data?.amount}
                //   //           subDescriptions={data?.subDescriptions}
                //   //           composition={data?.content}
                //   //           wishList={data?.WishlistProduct}
                //   //         />
                //   //       </div>
                //   //     </div>
                //   //   );
                //   // }

                //   // return (
                //   //   <div className="col-lg-3 col-sm-12">
                //   //     <div className='h-100' onClick={() => {
                //   //       handleProductClick(data)
                //   //     }} style={{ textDecoration: 'none', color: '#000' }}>
                //   //       <ProductBox
                //   //         key={data?._id}
                //   //         id={data?._id}
                //   //         //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
                //   //         colorId={data?.productImage?.color}
                //   //         img={data?.productImage?.front[0]}
                //   //         name={data?.name}
                //   //         price={data?.amount}
                //   //         subDescriptions={data?.subDescriptions}
                //   //         composition={data?.content}
                //   //         wishList={data?.WishlistProduct}
                //   //       />
                //   //     </div>
                //   //   </div>
                //   // )

                // })
                <>
                  <div className="col-lg-2 col-sm-12">
                    <div
                      className="h-100"
                      onClick={() => {
                        handleProductClick(obj);
                      }}
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <div className="custom-class">
                        <div className="custom-container">
                          <img
                            src={obj?.productImage[0]}
                            class="custom-image"
                            alt={"props"}
                          />

                          {/* {props?.isNew && ( */}
                          {/* <div class="custom-chip">
            <Chip value="New" className="chip" />
          </div> */}
                          {/* )} */}

                          {/* {props?.isProduct && ( */}
                          <div class="wishlist-button">
                            <Button class="wishlist-btn">
                              <FaRegHeart className="heart-icon" />
                            </Button>
                          </div>
                          {/* )} */}
                        </div>
                        <p
                          className="card-text"
                          style={{ fontWeight: 700, fontSize: "0.85rem", }}
                        >
                          {obj?.name}
                        </p>
                        <p className=" price">
                          ₹{obj?.specialAmount}
                          &nbsp;
                          <span className="specialAmount">₹{obj?.amount}</span>
                        </p>
                        <button
                          onClick={() => navigate("/products")}
                          className="explore-btn"
                        >
                          Add to cart
                        </button>
                      </div>
                      {/* <ProductBox
                        key={obj?._id}
                        id={obj?._id}
                        //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
                        colorId={
                          obj?.productImage?.filter(
                            (data) => data?.color?.name === "Black"
                          )?.color
                        }
                        img={obj?.productImage[0]}
                        name={obj?.name}
                        price={obj?.amount}
                        specialAmount={obj?.specialAmount}
                        subDescriptions={obj?.subDescriptions}
                        composition={obj?.content}
                        wishList={obj?.WishlistProduct}
                      /> */}
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-sm-12">
                <div
                  className="h-100"
                  onClick={() => {
                    handleProductClick(allProducts)
                  }}
                  style={{textDecoration: "none", color: "#000"}}>
                  <ProductBox
                    key={allProducts?._id}
                    id={allProducts?._id}
                    //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
                    colorId={
                      allProducts?.productImage?.filter(
                        (data) => data?.color?.name === "Red",
                      )?.color
                    }
                    img={
                      allProducts?.productImage?.filter(
                        (data) => data?.color?.name === "Red",
                      )?.[0]?.front?.[0]
                    }
                    name={allProducts?.name}
                    price={allProducts?.amount}
                    subDescriptions={allProducts?.subDescriptions}
                    composition={allProducts?.content}
                    wishList={allProducts?.WishlistProduct}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div
                  className="h-100"
                  onClick={() => {
                    handleProductClick(allProducts)
                  }}
                  style={{textDecoration: "none", color: "#000"}}>
                  <ProductBox
                    key={allProducts?._id}
                    id={allProducts?._id}
                    //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
                    colorId={
                      allProducts?.productImage?.filter(
                        (data) => data?.color?.name === "Silver",
                      )?.color
                    }
                    img={
                      allProducts?.productImage?.filter(
                        (data) => data?.color?.name === "Silver",
                      )?.[0]?.front?.[0]
                    }
                    name={allProducts?.name}
                    price={allProducts?.amount}
                    subDescriptions={allProducts?.subDescriptions}
                    composition={allProducts?.content}
                    wishList={allProducts?.WishlistProduct}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div
                  className="h-100"
                  onClick={() => {
                    handleProductClick(allProducts)
                  }}
                  style={{textDecoration: "none", color: "#000"}}>
                  <ProductBox
                    key={allProducts?._id}
                    id={allProducts?._id}
                    //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
                    colorId={
                      allProducts?.productImage?.filter(
                        (data) => data?.color?.name === "Maroon",
                      )?.color
                    }
                    img={
                      allProducts?.productImage?.filter(
                        (data) => data?.color?.name === "Maroon",
                      )?.[0]?.front?.[0]
                    }
                    name={allProducts?.name}
                    price={allProducts?.amount}
                    subDescriptions={allProducts?.subDescriptions}
                    composition={allProducts?.content}
                    wishList={allProducts?.WishlistProduct}
                  />
                </div>
              </div> */}
                </>
              );
            })
          ) : (
            // allProducts?.productImage?.map((data, id) => {

            //   if (data?.color?.name === "Black") {
            //     return (
            //       <div className="col-lg-3 col-sm-12">
            //         <div className='h-100' onClick={() => {
            //           handleProductClick(data)
            //         }} style={{ textDecoration: 'none', color: '#000' }}>
            //           {console.info(data)}
            //           <ProductBox
            //             key={allProducts?._id}
            //             id={allProducts?._id}
            //             //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
            //             colorId={data?.color}
            //             img={data?.front[0]}
            //             name={allProducts?.name}
            //             price={allProducts?.amount}
            //             subDescriptions={allProducts?.subDescriptions}
            //             composition={allProducts?.content}
            //             wishList={allProducts?.WishlistProduct}
            //           />
            //         </div>
            //       </div>
            //     );
            //   }
            //   if (data?.color?.name === "Red") {
            //     return (
            //       <div className="col-lg-3 col-sm-12">
            //         <div className='h-100' onClick={() => {
            //           handleProductClick(data)
            //         }} style={{ textDecoration: 'none', color: '#000' }}>
            //           {console.info(data)}
            //           <ProductBox
            //             key={allProducts?._id}
            //             id={allProducts?._id}
            //             //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
            //             colorId={data?.color}
            //             img={data?.front[0]}
            //             name={allProducts?.name}
            //             price={allProducts?.amount}
            //             subDescriptions={allProducts?.subDescriptions}
            //             composition={allProducts?.content}
            //             wishList={allProducts?.WishlistProduct}
            //           />
            //         </div>
            //       </div>
            //     );
            //   }
            //   if (data?.color?.name === "Silver") {
            //     return (
            //       <div className="col-lg-3 col-sm-12">
            //         <div className='h-100' onClick={() => {
            //           handleProductClick(data)
            //         }} style={{ textDecoration: 'none', color: '#000' }}>
            //           {console.info(data)}
            //           <ProductBox
            //             key={allProducts?._id}
            //             id={allProducts?._id}
            //             //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
            //             colorId={data?.color}
            //             img={data?.front[0]}
            //             name={allProducts?.name}
            //             price={allProducts?.amount}
            //             subDescriptions={allProducts?.subDescriptions}
            //             composition={allProducts?.content}
            //             wishList={allProducts?.WishlistProduct}
            //           />
            //         </div>
            //       </div>
            //     );
            //   }
            //   if (data?.color?.name === "Maroon") {
            //     return (
            //       <div className="col-lg-3 col-sm-12">
            //         <div className='h-100' onClick={() => {
            //           handleProductClick(data)
            //         }} style={{ textDecoration: 'none', color: '#000' }}>
            //           {console.info(data)}
            //           {console.info(allProducts)}
            //           <ProductBox
            //             key={allProducts?._id}
            //             id={allProducts?._id}
            //             //  colorId={data?.color[colorIdFilterFunction(data?.color, i)]}
            //             colorId={data?.color}
            //             img={data?.front[0]}
            //             name={allProducts?.name}
            //             price={allProducts?.amount}
            //             subDescriptions={allProducts?.subDescriptions}
            //             composition={allProducts?.content}
            //             wishList={allProducts?.WishlistProduct}
            //           />
            //         </div>
            //       </div>
            //     );
            //   }

            // })
            // <SpinnerComponent />
            <div className="bestseller">
              {Array.from({ length: 5 }).map((_, idx) => {
                return (
                  <div
                    key={idx}
                    class="custom-class"
                    style={{ maxWidth: "190px" }}
                  >
                    <div class="custom-container">
                      <div class="skeleton skeleton-image"></div>
                      <div class="wishlist-button">
                        <div class="skeleton skeleton-button"></div>
                      </div>
                    </div>
                    <div class="skeleton skeleton-text"></div>
                    <div class="skeleton skeleton-price"></div>
                    <div class="skeleton skeleton-button"></div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default TopSelling;
