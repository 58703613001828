/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import CheckIcon from "../assets/icons/check.png";
import fabrics from "../data/fabrics";
import productDetail from "../data/product-detail";
import { Accordion, Button } from "react-bootstrap";
import ReactQuill from "react-quill";

/* COMPONENT IMPORTS */
import Header from "../components/Header";
import Footer from "../components/Footer";
import NewArrivals from "../components/home/NewArrivals";
import { useDispatch, useSelector } from "react-redux";
import {
  addTocart,
  deleteReviews,
  getProductById,
  getReviews,
  postWishList,
} from "../service/api";
import { useEffect } from "react";
/*REDUX PRODUCT ACTION*/
import productActions from "../redux/products/actions";
import { IMG_URL } from "../config";
import { useSnackbar } from "notistack";
import { useUpdateCart } from "../hooks/useUpdateCart";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import RatingDialog from "./RatingDialog";
import { Input, Slider, Tooltip } from "@mui/material";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import { FaDeleteLeft, FaTrash } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
function ProductDetail() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setUpdateCart } = useUpdateCart();
  const [toggleImage, setToggleImage] = useState(0);
  const [count, setCount] = useState(1);
  const [selectedColor, setSelectedColor] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [average, setAverage] = useState(0);
  const [selectedColorCode, setSelectedColorCode] = useState(null);
  const [showSelectedColor, setShowSelectedColor] = useState(null);
  const [selectedImg, setSelectedImg] = useState("");
  const [apiCall, setApiCall] = useState("");
  const [wishlistActive, setwishlistActive] = useState(false);

  let {
    selectedProductId,
    selectedProduct,
    cart,
    wishListData,
    wishList,
    selectedProductColorId,
  } = useSelector((state) => state.products);

  if (id) {
    selectedProductId = id;
  }

  //
  const { loggedIn, user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  function increment() {
    setCount(function (prevCount) {
      return (prevCount += 1);
    });
  }

  function decrement() {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
  }

  useEffect(() => {
    getProductDetails();
  }, [selectedProductId, wishListData]);

  useEffect(() => {
    if (selectedProduct?.productImage?.length > 0) {
      setSelectedColor(selectedProduct?.productImage);
      // setShowSelectedColor(data1?.color?.name);
      setSelectedImg(selectedProduct?.productImage[0]);
    }
  }, [selectedProduct]);

  const getReviewData = () => {
    getReviews({ page: 1, sizePerPage: 10, product: id })
      .then((res) => {
        if (res?.status) {
          const sortedReviews = res?.data?.docs.sort((a, b) => {
            if (a?.addedBy?._id === user?._id) return -1;
            if (b?.addedBy?._id === user?._id) return 1;
            return 0;
          });
          setReviewData(sortedReviews);
          let arr =
            res?.data?.docs && res?.data?.docs?.length > 0
              ? res?.data?.docs?.reduce((acc, item) => acc + item.rating, 0) /
                res?.data?.docs?.length
              : 0;
          setAverage(arr);
        }
      })
      .catch((e) => {});
  };
  const handleDelete = (id) => {
    deleteReviews(id)
      ?.then((res) => {
        if (res?.status) {
          getReviewData();
        }
      })
      .catch((e) => {});
  };
  useEffect(() => {
    getReviewData();
  }, []);
  // useEffect(() => {
  //   if (selectedColor?.front?.length > 0) {
  //     setSelectedImg(selectedColor?.front[0]);
  //   } else {
  //     setSelectedImg(selectedColor?.back[0]);
  //   }
  // }, [selectedColor]);

  const getProductDetails = () => {
    if (selectedProductId) {
      setLoading(true);
      getProductById(selectedProductId)
        .then((res) => {
          if (res?.status) {
            if (
              res?.data?.whishListProduct?.filter(
                (wd) => wd?.color === wishListData?.colorId
              )?.length > 0
            ) {
              setwishlistActive(true);
            } else {
              setwishlistActive(false);
            }
            dispatch(productActions.setSelectedProduct(res?.data?.productData));
            console.log(
              "%c Line:155 🌭 res?.data?.productData",
              "color:#465975",
              res?.data?.productData
            );
          }
        })
        .finally(() => setLoading(false));
    } else {
      navigate("/products");
    }
  };
  const handleAddToCart = (tag) => {
    if (!loggedIn) {
      navigate("/register");
      return;
    }

    if (count === 0) {
      enqueueSnackbar("Add the Quantity you want to buy.", {
        variant: "error",
      });
      return;
    }

    setApiCall(true);
    // if (loggedIn) {
    let body = {
      product: selectedProduct?._id,
      color: selectedProduct?.pattern?.name,
      quantity: count,
    };
    // const findColor = selectedProduct?.quantity.find(
    //     (element) => element?.color?._id === selectedColor?.color?._id,
    // );
    // if (findColor.quantity > 3) {
    setCount(1);
    addTocart(body).then((res) => {
      setApiCall(false);
      enqueueSnackbar(res?.message, { variant: "success" });
      if (res?.status) {
        if (tag === "buyNow") {
          navigate("/cart");
        }
        setCount(0);
        setUpdateCart(true);
      }
    });
    // } else {
    // setApiCall(false);
    // enqueueSnackbar("Temporarily Out of Stock.", {
    //     variant: "warning",
    // });
    // }
    // } else {
    //   if (
    //     cart.filter(
    //       (obj) => obj.product?._id === selectedProduct?._id,
    //       // obj.color?._id === selectedColor?.color?._id,
    //     )?.length > 0
    //   ) {
    // const findColor = selectedProduct?.quantity
    //

    let data = cart.filter(
      (obj) =>
        obj.product?._id !== selectedProduct?._id ||
        obj.color?._id !== selectedColor?.color?._id
    );
    let product = cart.filter(
      (obj) =>
        obj.product?._id === selectedProduct?._id &&
        obj.color?._id === selectedColor?.color?._id
    )[0];

    product.quantity = product?.quantity + count;

    data.push(product);
    // productActions.setCartData(data);
    dispatch(productActions.setCartData(data));

    enqueueSnackbar("Your product has been added to cart successfully.", {
      variant: "success",
    });
    // } else {
    // const findColor = selectedProduct?.quantity.find(
    //     (element) =>
    //         element?.color?._id === selectedColor?.color?._id,
    // );

    // if (findColor.quantity > 3) {
    setApiCall(false);
    setCount(1);
    enqueueSnackbar("Your product has been added to cart successfully.", {
      variant: "success",
    });

    dispatch(
      productActions.setCartData([
        ...cart,
        {
          product: selectedProduct,
          quantity: count,
          color: selectedColor?.color,
          amount: selectedProduct?.specialAmount
            ? selectedProduct?.specialAmount
            : selectedProduct?.amount,
        },
      ])
    );
    // } else {
    //     setApiCall(false);
    //     enqueueSnackbar("Temporarily Out of Stock.", {
    //         variant: "warning",
    //     });
    // }

    // selectedProduct?.quantity.forEach(element => {

    //   // if (element?.color?._id === selectedColor?.color?._id) {

    //   // } else {
    //   //   cartEligible = false
    //   // }
    //
    //
    //
    // });

    if (tag === "buyNow") {
      navigate("/cart");
    }

    setApiCall(false);
  };

  // useEffect(() => {
  //
  //   setSelectedColorCode(wishListData?.colorId)
  //   setwishlistActive(wishListData?.wishlistActive)
  // }, [wishListData])

  useEffect(() => {
    if (selectedColorCode) {
      getProductById(selectedProductId).then((res) => {
        if (res?.status) {
          if (
            res?.data?.whishListProduct?.filter(
              (wd) => wd?.color === selectedColorCode
            )?.length > 0
          ) {
            setwishlistActive(true);
          } else {
            setwishlistActive(false);
          }
        }
      });
    }
  }, [selectedColorCode]);

  const wishListFun = (e) => {
    e.stopPropagation();
    if (!loggedIn) {
      dispatch(productActions.setModal(true));
      return;
    }

    if (wishlistActive) {
      setwishlistActive(false);
      const formData = new FormData();
      formData.append("product", wishListData?.productId);

      if (selectedColorCode) formData.append("color", selectedColorCode);
      else formData.append("color", wishListData?.colorId);

      postWishList(formData).then((res) => {});
      dispatch(productActions.setWishList(wishList - 1));
    } else {
      const formData = new FormData();
      formData.append("product", wishListData?.productId);
      if (selectedColorCode) formData.append("color", selectedColorCode);
      else formData.append("color", wishListData?.colorId);
      postWishList(formData).then((res) => {});
      dispatch(productActions.setWishList(wishList + 1));
      setwishlistActive(true);
    }
  };

  const RatingCard = (props) => {
    return (
      <div
        style={{
          padding: "10px",
          borderBottom: "1px solid grey",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <p>{props?.data?.title}</p>
          <p style={{ color: "grey", fontSize: "16px" }}>
            {moment(props?.data?.createdAt).format("DD MMM YY")}
          </p>
        </div>
        <Rating
          disabled={true}
          readonly={true}
          // onClick={() => }
          initialValue={props?.data?.rating}
          size={25}
          label
          transition
          fillColor="gold"
          emptyColor="gray"
        />
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <p
            style={{
              flex: 1,
              margin: 0,
              wordBreak: "break-word",
            }}
          >
            {props?.data?.desc}
          </p>
          {props?.data?.addedBy?._id === user?._id && (
            <Tooltip title="Delete" arrow>
              <FaTrash
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDelete(props?.data?._id);
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="main">
      <div className="product-detail">
        <section className="mt-50">
          <section className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="product-detail-section">
                  <div className="row mb-50">
                    {/* LEFT SIDE */}
                    <div className="col-md-7 col-sm-12 sticky-pos">
                      <div className="detail-gallery">
                        <div className="row">
                          {/* Thumbnails */}
                          <div className="col-lg-3 thumbnails-list">
                            {loading ? (
                              <div className="skeleton skeleton-thumbnail"></div>
                            ) : (
                              selectedColor.map((image, index) => (
                                <div
                                  key={index}
                                  className="product-image mb-3"
                                  onClick={() => setSelectedImg(image)}
                                >
                                  <img
                                    src={IMG_URL + image}
                                    alt={selectedProduct?.name || "Product"}
                                  />
                                </div>
                              ))
                            )}
                          </div>

                          {/* Main Image */}
                          <div className="col-lg-9">
                            <div className="product-image">
                              {loading ? (
                                <div className="skeleton skeleton-image-details"></div>
                              ) : (
                                <img
                                  src={IMG_URL + selectedImg}
                                  alt={selectedProduct?.name || "Product"}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* RIGHT SIDE */}
                    <div className="col-md-5 col-sm-12">
                      <div className="detail-info">
                        {loading ? (
                          <>
                            <div className="skeleton skeleton-title"></div>
                            <div className="skeleton skeleton-text"></div>
                          </>
                        ) : (
                          <>
                            <h2 className="title-detail">
                              {selectedProduct?.name}
                            </h2>
                            <p>
                              <b>Article No</b>: {selectedProduct?.articleNo}
                            </p>
                          </>
                        )}
                      </div>

                      <div
                        className="wishlist-option-detail"
                        onClick={wishListFun}
                      >
                        {wishlistActive ? (
                          <svg
                            stroke="red"
                            fill="red"
                            stroke-width="0"
                            viewBox="0 0 1024 1024"
                            height="1.2em"
                            width="1.2em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z"></path>
                          </svg>
                        ) : (
                          <>
                            <svg
                              className="display-not-hover"
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 1024 1024"
                              height="1.2em"
                              width="1.2em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z"></path>
                            </svg>
                            <svg
                              className="display-hover"
                              stroke="red"
                              fill="red"
                              stroke-width="0"
                              viewBox="0 0 1024 1024"
                              height="1.2em"
                              width="1.2em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z"></path>
                            </svg>
                          </>
                        )}
                      </div>

                      {/* DESCRIPTION */}
                      <Accordion className="product-detail-accordion mt-3">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Product Details</Accordion.Header>
                          <Accordion.Body>
                            <ReactQuill
                              className="mb-2"
                              id="marketplaceDescription"
                              value={selectedProduct?.desc}
                              readOnly={true}
                              theme={"bubble"}
                            />

                            {/* <p className="mb-2">{selectedProduct?.desc}</p> */}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="note mb-20 mt-2">
                        {selectedProduct?.subDescriptions &&
                          Object.entries(selectedProduct?.subDescriptions)?.map(
                            (key) => {
                              return (
                                <>{loading ? ( <>
                                  {/* <div className="skeleton skeleton-title"></div> */}
                                  <div className="skeleton skeleton-text"></div>
                                </>):(<p className="mb-2">
                                    
                                    <b
                                      id="subDescriptions"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {key[0]
                                        .toLowerCase()
                                        .replace(/\b(\w)/g, (x) =>
                                          x.toUpperCase()
                                        )}
                                      &nbsp;:&nbsp;
                                    </b>
                                    {key[1]}
                                  </p>)}
                                  
                                </>
                              );
                            }
                          )}
                      </div>

                      <div className="product-price-cover mt-2">
                        <div>
                          {loading ? ( <>
                            <div className="skeleton skeleton-title"></div>
                            <div className="skeleton skeleton-text"></div>
                          </>):(<><p>
                            {selectedProduct?.specialAmount ? (
                              selectedProduct?.specialAmount > 0 ? (
                                <>
                                  <span
                                    style={{
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {"₹" + selectedProduct?.amount}
                                  </span>{" "}
                                  <span
                                    style={{
                                      color: "#FF0000",
                                    }}
                                  >
                                    {"₹" + selectedProduct?.specialAmount}
                                  </span>
                                </>
                              ) : (
                                "₹" + selectedProduct?.amount
                              )
                            ) : (
                              "₹" + selectedProduct?.amount
                            )}
                            &nbsp;
                          </p>
                          {selectedProduct?.specialAmount &&
                            selectedProduct.specialAmount > 0 && (
                              <p
                                style={{
                                  fontSize: "18px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  {(
                                    ((selectedProduct.amount -
                                      selectedProduct.specialAmount) /
                                      selectedProduct.amount) *
                                    100
                                  ).toFixed(0)}
                                  % Off
                                </span>
                              </p>
                            )}</>)}
                          
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          <img src={CheckIcon} width={15} alt="In stock" />
                          <p
                            style={{
                              marginBottom: "0px",
                              fontSize: "14px",
                              marginLeft: "5px",
                            }}
                          >
                            In stock
                          </p>
                        </div>
                      </div>

                      <div className="mt-15 mb-15" />

                      {/* ACTION BUTTONS */}
                      <div className="action-buttons">
                        <div className="quantity-counter">
                          <Button className="minus-counter" onClick={decrement}>
                            -
                          </Button>
                          <div className="count">
                            <p>{count}</p>
                          </div>
                          <Button className="plus-counter" onClick={increment}>
                            +
                          </Button>
                        </div>
                        <Button
                          className="add-to-cart-btn"
                          onClick={handleAddToCart}
                          disabled={apiCall}
                        >
                          Add to cart
                        </Button>
                        <Button
                          className="buy-now-btn"
                          onClick={() => handleAddToCart("buyNow")}
                          disabled={apiCall}
                        >
                          Buy Now
                        </Button>
                      </div>
                      {/* Ratings and reveiws */}
                      <div className="rating">
                        <div className="rating-header">
                          <h5>Ratings & Reviews</h5>

                          {loggedIn &&
                            reviewData &&
                            reviewData.length > 0 &&
                            !reviewData.some(
                              (obj) => obj.addedBy._id === user?._id
                            ) && (
                              <p
                                onClick={() => {
                                  setOpen(true);
                                }}
                                className="rate-btn"
                              >
                                Rate Product
                              </p>
                            )}
                        </div>
                        <div>
                          {average !== 0 && <h2>{average} ★</h2>}

                          {reviewData && reviewData?.length > 0
                            ? reviewData?.map((obj, index) => {
                                return <RatingCard data={obj} key={index} />;
                              })
                            : "Be the first to share your thoughts on this product!"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <NewArrivals />
        <RatingDialog
          id={id}
          open={open}
          setOpen={setOpen}
          loadData={getReviewData}
        />
      </div>
    </div>
  );
}

export default ProductDetail;
