import React, { useState, useRef } from "react";
// import {Button} from "react-bootstrap"
import Carousel from "react-bootstrap/Carousel";
import SliderLeft from "../assets/icons/slider-left.svg";
import SliderRight from "../assets/icons/slider-right.svg";
import { useNavigate } from "react-router-dom";

import desktop01 from "../assets/NewPoster/desktop01.jpg";
import desktop02 from "../assets/NewPoster/desktop02.jpg";
import desktop03 from "../assets/NewPoster/desktop03.jpg";
import desktop04 from "../assets/NewPoster/desktop04.jpg";
import mobile01 from "../assets/mobileBanner/new1.jpg";
import mobile02 from "../assets/mobileBanner/new2.jpg";
import mobile03 from "../assets/mobileBanner/new3.jpg";
import mobile04 from "../assets/mobileBanner/new4.jpg";
import mobile05 from "../assets/mobileBanner/new5.jpg";
import mobile06 from "../assets/mobileBanner/new6.jpg";

import one from "../assets/banners/one.png";
import two from "../assets/banners/two.png";
import three from "../assets/banners/three.png";
import mone from "../assets/banners/mone.png";
import mtwo from "../assets/banners/mtwo.png";
import mthree from "../assets/banners/mthree.png";
import mfour from "../assets/banners/mfour.png";
import mfive from "../assets/banners/mfive.png";
import { MdOutlineTwoWheeler } from "react-icons/md";

// import SeasonalMobileBanner from "../assets/banners/new/Body wash.jpg"
// import SeasonalMobilePowder from "../assets/banners/new/04.jpg"
// import powder from "../assets/banners/new/031.jpg"
// import lieve from "../assets/banners/new/0111.jpg"
// import SeasonalMobileBanner from "../assets/banners/seasonal-banner-mobile-2.png"
// import WholesaleBanner from "../assets/banners/05.jpg"
// import FrontBanner from "../assets/banners/front-banner-02.webp"
// import FrontMobileBanner from "../assets/banners/front-banner-mobile.png"
function Banner() {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  // const showOnMobile = document.innerWidth < 650
  // console.log("%c Line:23 🍞 showOnMobile", "color:#42b983", showOnMobile);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const showOnMobile = windowSize?.current?.[0] < 769;
  // console.info(windowSize?.current?.[0])
  console.log(showOnMobile, windowSize?.current?.[0])
  return (
    <section className="banner-section">
      <Carousel
        className="banner"
        nextIcon={<img src={SliderRight} alt="Slider Icon" />}
        prevIcon={<img src={SliderLeft} alt="Slider Icon" />}
        activeIndex={index}
        onSelect={handleSelect}
      >
        <Carousel.Item interval={1500}>
          {!showOnMobile ? (
            <img
              src={one}
              style={{maxWidth: "100%", }}
              alt="Lieve1"
            />
          ) : (
            <img
              src={mtwo}
              style={{maxWidth: "100%", height: "100vh"}}
              alt="Lieve2"
            />
          )}
        
        </Carousel.Item>
        <Carousel.Item interval={1500}>
          {!showOnMobile ? (
            <img
              src={two}
              style={{maxWidth: "100%", }}
              alt="Lieve3"
            />
          ) : (
            <img
              src={mfive}
              style={{maxWidth: "100%", height: "100vh"}}
              alt="Lieve"
            />
          )}
        
        </Carousel.Item>
        <Carousel.Item interval={1500}>
          {!showOnMobile ? (
            <img
              src={three}
              style={{maxWidth: "100%", }}
              alt="Lieve"
            />
          ) : (
            <img
              src={mthree}
              style={{maxWidth: "100%", height: "100vh"}}
              alt="Lieve"
            />
          )}
        
        </Carousel.Item>
        {/* <Carousel.Item interval={1500}>
          {!showOnMobile ? (
            <img
              src={one}
              alt="Lieve"
            />
          ) : (
            <img
              src={mobile01}
              alt="Lieve"
            />
          )}
          
        </Carousel.Item> */}

        {/* <Carousel.Item interval={1500}>
          {!showOnMobile ? (
            <img src={two} alt="Lieve" />
          ) : (
            <img src={mobile02} alt="Lieve" />
          )}
         
        </Carousel.Item> */}
        {/* <Carousel.Item interval={1500}>
          {!showOnMobile ? (
            <img src={three} alt="Lieve" />
          ) : (
            <img src={mobile03} alt="Lieve" />
          )}
        </Carousel.Item> */}
        {/* <Carousel.Item interval={1500}>
          {!showOnMobile ? (
            <img src={desktop04} alt="Lieve" />
          ) : (
            <img src={mobile05} alt="Lieve" />
          )}
        </Carousel.Item> */}
        {/* {showOnMobile && (
          <Carousel.Item>
            <img src={mobile04} alt="Lieve" />
          </Carousel.Item>
        )} */}
        {/* {showOnMobile && (
          <Carousel.Item>
            <img src={mobile05} alt="Lieve" />
          </Carousel.Item>
        )} */}
        {/* {showOnMobile && (
          <Carousel.Item>
            <img src={mobile06} alt="Lieve" />
          </Carousel.Item>
        )} */}
        {/* <Carousel.Item>
          <img
            src={showOnMobile ? SeasonalMobileBanner : SeasonalBanner}
            style={{maxWidth: "100%", maxHeight: "100%"}}
            alt="Inspiroworld International"
          />
          <Carousel.Caption>
            <div className="container">
              <div className="row mb-50 justify-content-end">
                <div
                  className={
                    `col-xl-6 col-sm-5` + (showOnMobile ? "mobile-banner" : "")
                  }>
                  <div
                    className="hero-slide-content wholesale-textbox third-banner bg-white"
                    id="banner-2">
                    <h2 className="title-1">SPRING</h2>
                    <h2 className="title-1">COLLECTION</h2>
                    <h5>The right fabric for the season</h5>
                    <Button
                      className={
                        showOnMobile
                          ? "view-collection responsive-button"
                          : "view-collection"
                      }
                      onClick={() => {
                        navigate(`/products`)
                      }}>
                      VIEW COLLECTION
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item> */}
      </Carousel>
    </section>
  );
}

export default Banner;
